<template>
  <div class="p-5 xl:px-0">
    <div class="flex items-center mb-4">
      <a href="/curriculum">
        <font-awesome-icon icon="arrow-left" />
      </a>
      <div  :class="topic.area.colour" class="area flex  flex-grow items-center justify-center text-3xl" v-if="topic.area">
        <div v-html="topic.legend" class="area__topic mr-4"></div>
        <h1 class="text-xl text-center" v-html="topic.title"></h1>
      </div>
      
    </div>

    <h2 class="mb-4">Key Concepts</h2>

    <transition name="fade" mode="out-in">
      <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="concepts.length">
        <ol role="list" class="divide-y divide-gray-200 list-decimal list-inside">
          <li v-for="(concept, i) in concepts" :key="concept.id" class="flex items-center pr-6 hover:bg-gray-50 ">
              <a :href="'/concept/'+concept.id" class="px-4 py-4 flex items-center sm:px-6 flex-grow">
                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div class="truncate">
                    <div class="flex text-sm">
                      <p class="font-medium text-blue-600 truncate">{{i+1}}. {{ concept.title }}</p>
                    </div>
                  </div>
                </div>
                </a>
                <div class="ml-5 flex-shrink-0 ">
                  <div class="flex">
                    <modal title="Concept" class="cursor-pointer" component="Concept" :attributes="{ topic_id: topic_id, payload: concept }" width="lg" buttonClass="mx-1" v-permission="['concepts.edit']">
                      <font-awesome-icon icon="pen" />
                    </modal>

                    <modal title="Are you sure?" class="cursor-pointer ml-6" buttonClass="text-red-600" component="DeleteConfirm" :attributes="{ topic_id: topic_id, message: 'Are you sure you want to delete this concept?', id: concept.id, resource: 'concepts' }" v-permission="['concepts.delete']">
                      <font-awesome-icon icon="times" v-permission="['concepts.delete']" />
                    </modal>
                </div>
              </div>
          </li>
        </ol>
      </div>

      <p v-else>There are no key concepts for this topic yet</p>
    </transition>

      <h2 class="my-4"  v-permission="['skills.view']">Skills</h2>

      <transition name="fade" mode="out-in"  v-permission="['skills.view']">
      <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="skills.length">
        <ol role="list" class="divide-y divide-gray-200 list-decimal list-inside">
          <li v-for="(skill, i) in skills" :key="skill.id" class="flex items-center pr-6 hover:bg-gray-50 ">
              <a :href="'/skills/'+skill.id" class="px-4 py-4 flex items-center sm:px-6 flex-grow">
                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div class="truncate">
                    <div class="flex text-sm">
                      <p class="font-medium text-blue-600 truncate">{{i+1}}. {{ skill.name }}</p>
                    </div>
                  </div>
                </div>
                </a>
                <div class="ml-5 flex-shrink-0 ">
                  <div class="flex">
                    <modal title="Skill" class="cursor-pointer" component="Skill" :attributes="{ payload: skill }" width="xl" buttonClass="mx-1" v-permission="['skills.edit']">
                      <font-awesome-icon icon="pen" />
                    </modal>

                    <modal title="Are you sure?" class="cursor-pointer ml-6" buttonClass="text-red-600" component="DeleteConfirm" :attributes="{ message: 'Are you sure you want to delete this skill?', id: skill.id, resource: 'skills' }" v-permission="['skills.delete']">
                      <font-awesome-icon icon="times" v-permission="['skills.delete']" />
                    </modal>
                </div>
              </div>
          </li>
        </ol>
      </div>

      <p v-else>There are no key skills for this topic yet</p>
    </transition>
    <transition name="fade">
      <FlashMessage :message="message" v-if="message" />
      <FlashMessage :error="error" v-if="error" key="error" />
    </transition>

    <footer class="fixed left-0 bottom-0 w-full bg-gray-900" v-permission="['concepts.edit']">
      <div class="flex items-center justify-between flex-wrap bg-teal-500 p-6 container mx-auto">
        <modal title="Concept" class="cursor-pointer" component="Concept" width="lg" :attributes="{ topic_id: topic_id }">
          <BaseBtn type="span" icon="plus" text="Add Concept" class="border-white" />
        </modal>

        <modal title="Skill" class="cursor-pointer" component="Skill" width="xl" :attributes="{ topic_id: topic_id }"  v-permission="['skills.edit']">
          <BaseBtn type="span" icon="plus" text="Add Skill" class="border-white" />
        </modal>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPen, faKey, faTrash, faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/BaseBtn";

library.add(faPen, faKey, faTrash, faTimes, faArrowLeft);

export default {
  name: "TopicView",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn },
  computed: {
    ...mapGetters("topic", ["loading", "error", "concepts", "topic", 'skills', "meta", "links"]),
  },
  data() {
    return {
      topic_id: "",
      message: "",
    };
  },
  created() {
    this.topic_id = this.$route.params.topic_id;

    this.getConcepts();
    this.getTopic();
    this.getSkills();

    this.$eventBus.$on("concepts:updated", (val) => {
      this.getConcepts();
      this.message = 'Concept updated'
    });
    this.$eventBus.$on("concepts:added", (val) => {
      this.getConcepts();
      this.message = 'Concept added'
    });
    this.$eventBus.$on("skills:updated", (val) => {
      this.getSkills();
      this.message = 'Skill updated'
    });
    this.$eventBus.$on("skills:added", (val) => {
      this.getSkills();
      this.message = 'Skill added'
    });
    this.$eventBus.$on("record:deleted", (val) => {
      this.getConcepts();
      this.message = 'Concept deleted'
    });
  },
  methods: {
    getConcepts() {
      this.$store.dispatch("topic/getConcepts", this.topic_id);
    },

    getTopic() {
      this.$store.dispatch("topic/getTopic", this.topic_id);
    },

    getSkills() {
      this.$store.dispatch("topic/getSkills", this.topic_id);
    },
  },
};
</script>
